@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";
@import "@webpixels/css/utilities";

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.as-script {
    font-family: 'Dancing Script', cursive;
}

.map-iframe {
    max-width: 100%;
}

.sticky-back-button {
    position: sticky;
    top: 0;
    background: white;
}